import { useQuery } from "@apollo/client";
import Status from "components/Status";
import moment from "moment";
import { SUPPLIER_FETCH_SO_RELATED_PURCHASE_ORDERS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";

const PlanUsagesView = ({ stock, productOdooId }) => {
  let left = stock.qty;
  const vendorId = stock.supplier.odooId;

  const usages = stock.usages
    .filter((i) => i.plan && i.qty > 0)
    .map((i) => {
      left = left - i.qty;
      return { ...i, left };
    });

  // just show so's po number, exclude transfer
  const soNumbers = usages.map((i) => i.plan.odooObject?.objectType === "ORDER" ? i.plan.odooObject?.objectName : null).filter((i) => i);

  const { loading, error, data } = useQuery(SUPPLIER_FETCH_SO_RELATED_PURCHASE_ORDERS, {
    variables: { vendorId, productOdooId, soNumbers },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const soToPosMapping = data.soRelatedPos.reduce((acc, item) => {
    acc[item.so] = item.pos;
    return acc;
  }, {});

  return (
    <div>
      <div className="flex justify-between font-semibold text-lg">
        <div>Last updated: {moment(stock.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</div>
        <div>{stock.qty}</div>
      </div>

      <div className="-mx-2 mt-2 whitespace-nowrap">
        <table>
          <thead>
            <tr className="border-y">
              <th>Plan 计划</th>
              <th className="w-full px-6">Order 订单</th>
              <th className="w-full px-6">PO 采购单</th>
              <th className="text-right px-6">QTY 订单数量</th>
              <th className="text-right">Left 剩余数量</th>
            </tr>
          </thead>
          <tbody>
            {usages.map((usage) => {
              const poList = soToPosMapping[usage.plan.odooObject?.objectName] || [];
              return (
                <tr key={usage.id} className=" border-y dark:border-gray-700">
                  <td>
                    <div>{usage.plan.name}</div>
                    <div className="text-xs opacity-70 whitespace-normal">{usage.plan.wisCustomer?.name}</div>
                    <Status status={usage.plan.state} />
                  </td>
                  <td className="px-6">{usage.plan.odooObject?.objectName}</td>
                  <td className="px-6">
                    {poList.length > 0 ? poList.map((po) => (
                      <div key={po}>{po}</div>
                    )) : <div className="text-center">/</div>}
                  </td>
                  <td className="text-right px-6">{usage.qty}</td>
                  <td className="text-right">{usage.left}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="text-lg font-semibold">
            <tr>
              <td colSpan={4}>Left</td>
              <td className=" text-right">{left}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default PlanUsagesView;
