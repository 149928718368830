import { useQuery } from "@apollo/client";
import Page from "components/Page";
import { SUPPLIER_FETCH_INVENTORY } from "./graphql";
import { useContext } from "react";
import { AppContext } from "AppContainer";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { parseError } from "Apollo";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "components/Button";
import { useModals } from "components/Modal";
import PlanUsagesView from "./PlanUsagesView";

const InventoryList = () => {
  const { user } = useContext(AppContext);
  const modal = useModals();

  const { loading, error, data } = useQuery(SUPPLIER_FETCH_INVENTORY, {
    variables: { supplierId: user.supplier.id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors errors={parseError(error)} />;

  const products = data.products.results.filter((i) => i.latestSupplierStock.active).sort((a, b) => a.number.localeCompare(b.number));
  const productsHasNoStock = products.filter((i) => i.latestSupplierStock.latestQty === 0);

  function showUsages(stock) {
    console.log(stock);
    modal.present({
      title: "库存占用计划",
      children: <PlanUsagesView stock={stock.latestSupplierStock} productOdooId={stock.odooId} />,
    });
  }

  return (
    <Page
      title="Inventory"
      topbarRightView={
        <div>
          <Link to="request-update">Request Update</Link>
        </div>
      }
    >
      <div className="mx-10 my-6 text-sm opacity-70">
        This page shows the inventory number Waboba owned in our supplier.
        <br />
        Waboba will manage the inventory numbers during our operation. You can also submit an inventory update anytime if you want then we will review and
        update the inventory number accordingly.
        <br />
        本页面显示Waboba在我们供应商处拥有的库存数量。Waboba将在我们的运营期间管理库存数量。您也可以随时提交库存更新，我们将审核并相应更新库存数量。
      </div>

      <div className="card m-8 mt-0 flex-1 flex flex-col overflow-auto p-0 rounded-xl">
        <div className="overflow-auto relative text-sm">
          <table>
            <thead>
              <tr>
                <th className="px-6 py-3">
                  Product
                  <br />
                  产品
                </th>
                <th className="px-6 py-3 text-right">
                  Initial QTY
                  <br />
                  上次更新库存
                </th>
                <th className="px-6 py-3 text-right">
                  Planing QTY
                  <br />
                  计划使用的数量
                </th>
                <th className="px-6 py-3 text-right">
                  Latest QTY
                  <br />
                  最终数量
                </th>
                <th className="px-6 py-3 text-right">
                  Ready Date
                  <br />
                  库存可用时间
                </th>
                <th className="px-6 py-3 text-right">
                  Remark
                  <br />
                  备注
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr
                  key={product.id}
                  className={`
                    hover:bg-blue-50 dark:hover:bg-gray-800 border-b dark:border-gray-700
                    ${product.latestSupplierStock.latestQty === 0
                      ? "text-red-600 bg-red-50 dark:text-red-500 dark:bg-red-900 dark:hover:bg-red-900 dark:bg-opacity-20 dark:hover:bg-opacity-50 border-red-100"
                      : "border-gray-100 dark:border-gray-100"
                    }
                  `}
                >
                  <td className="px-6">
                    [{product.number}] {product.name}
                  </td>
                  <td className="px-6 text-right text-gray-800 dark:text-gray-100">
                    {product.latestSupplierStock.usages.length > 0 ? (
                      <div className="flex justify-end">
                        <Button onClick={() => showUsages(product)}>{product.latestSupplierStock.qty}</Button>
                      </div>
                    ) : (
                      product.latestSupplierStock.qty
                    )}
                  </td>
                  <td className="px-6 text-right text-gray-800 dark:text-gray-100">{product.latestSupplierStock.planingPlanConsumedTotal}</td>
                  <td className="px-6 text-right text-gray-800 dark:text-gray-100">{product.latestSupplierStock.latestQty}</td>
                  <td className="px-6 text-right text-gray-800 dark:text-gray-100">{moment(product.latestSupplierStock.readyDate).format("YYYY-MM-DD")}</td>
                  <td className="px-6 text-right text-gray-800 dark:text-gray-100">{product.latestSupplierStock.remark}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="italic opacity-70 text-sm px-6 py-3 border-t border-gray-100 dark:border-gray-700">
          <span>Total {products.length} products. </span>
          {productsHasNoStock.length > 0 ? <span className="text-red-600">{productsHasNoStock.length} products have no stock.</span> : null}
        </div>
      </div>
    </Page>
  );
};

export default InventoryList;
