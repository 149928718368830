import { gql, useQuery } from "@apollo/client";
import AppRoutes from "./AppRoutes";
import { createContext, useEffect, useState } from "react";
import InitializingView from "components/InitializingView";
import ModalProvider from "components/Modal";

export const AppContext = createContext();

const FETCH_ME = gql`
  query FETCH_ME {
    me {
      id
      email
      supplier {
        id
        name
      }
    }
  }
`;

const AppContainer = () => {
  const { data, loading } = useQuery(FETCH_ME);
  const [showMenu, setShowMenu] = useState(localStorage.getItem("showMenu") === null ? true : localStorage.getItem("showMenu") === "1" ? true : false);

  useEffect(() => {
    localStorage.setItem("showMenu", showMenu ? "1" : "0");
  }, [showMenu]);

  if (loading) return <InitializingView />;

  const user = data ? data.me : null;

  return (
    <AppContext.Provider value={{ user, showMenu, setShowMenu }}>
      <ModalProvider>
        <AppRoutes />
      </ModalProvider>
    </AppContext.Provider>
  );
};

export default AppContainer;
