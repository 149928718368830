import { Route, Routes } from "react-router-dom";
import InventoryList from "./InventoryList";
import RequestUpdate from "./RequestUpdate";

const Inventory = () => {
  return (
    <Routes>
      <Route path="/" element={<InventoryList />} />
      <Route path="/request-update" element={<RequestUpdate />} />
    </Routes>
  );
};

export default Inventory;
